import React, { Component } from 'react';

import ScheduledWorkoutCardSimple from './Cards/ScheduledWorkoutCardSimple';
import WorkoutCardSimple from './Cards/WorkoutCardSimple';
import EatingLogEntryCardSimple from './Cards/EatingLogEntryCardSimple';
import SleepingLogEntryCardSimple from './Cards/SleepingLogEntryCardSimple';

const API_KEY = "keyxM8Ensox0r54qj";
const BASE_ID = "appo5RtkVFNUYfYSo";

const TABLE_ID = {
  SCHEDULED_WORKOUTS_TABLE_ID: "tblb0ChjaGlbtn4DN",
  WORKOUTS_TABLE_ID: "tbloXkAvjqpCiET7Q",
  EATING_LOG_TABLE_ID: "tblAWZh90oEHM8T0a",
  SLEEP_LOG_TABLE_ID: "tbl35gEHtoKaqIfeE",
};

const VIEW_ID = {
  todaysScheduledWorkoutsView: "viwiAACzNabYawXI1",
  todaysWorkoutsView: "viwfVAVWzfXadcWu6",
  todaysEatingLogsView: "viwfOxC0kZRQrIodO",
  todaysSleepLogView: "viw5rGsAMRzzvB5Iq",
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      todaysScheduledWorkouts: [],
      todaysWorkouts: [],
      todaysEatingLogs: [],
      todaysSleepLog: [],
    };
  }

  componentDidMount() {
    var todaysScheduledWorkoutsUrl = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_ID["SCHEDULED_WORKOUTS_TABLE_ID"]}?api_key=${API_KEY}&view=${VIEW_ID["todaysScheduledWorkoutsView"]}`
    var todaysWorkoutsUrl = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_ID["WORKOUTS_TABLE_ID"]}?api_key=${API_KEY}&view=${VIEW_ID["todaysWorkoutsView"]}`
    var todaysEatingLogsUrl = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_ID["EATING_LOG_TABLE_ID"]}?api_key=${API_KEY}&view=${VIEW_ID["todaysEatingLogsView"]}`
    var todaysSleepLogUrl = `https://api.airtable.com/v0/${BASE_ID}/${TABLE_ID["SLEEP_LOG_TABLE_ID"]}?api_key=${API_KEY}&view=${VIEW_ID["todaysSleepLogView"]}`
    // console.log(url)
    fetch(todaysScheduledWorkoutsUrl)
    .then((resp) => resp.json())
    .then(data => {
      console.log(data.records);
      this.setState({ todaysScheduledWorkouts: data.records });
    }).catch(err => {
      // Error :(
    });
    fetch(todaysWorkoutsUrl)
    .then((resp) => resp.json())
    .then(data => {
      console.log(data.records);
      this.setState({ todaysWorkouts: data.records });
    }).catch(err => {
      // Error :(
    });
    fetch(todaysEatingLogsUrl)
    .then((resp) => resp.json())
    .then(data => {
      console.log(data.records);
      this.setState({ todaysEatingLogs: data.records });
    }).catch(err => {
      // Error :(
    });
    fetch(todaysSleepLogUrl)
    .then((resp) => resp.json())
    .then(data => {
      console.log(data.records);
      this.setState({ todaysSleepLog: data.records });
    }).catch(err => {
      // Error :(
    });
  }

  render() {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <div className="card-deck">
              <div className='card'>
                <div class="card-header">
                  Scheduled Workouts
                </div>
                {this.state.todaysScheduledWorkouts.map(entry => <ScheduledWorkoutCardSimple {...entry} key={entry.id} /> )}          
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-deck">
              <div className='card'>
                <div class="card-header">
                  Completed Workouts
                </div>
                {this.state.todaysWorkouts.map(entry => <WorkoutCardSimple {...entry} key={entry.id} /> )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-deck">
              <div className="card">
                <div class="card-header">
                  Eating
                </div>
                {this.state.todaysEatingLogs.map(entry => <EatingLogEntryCardSimple {...entry} key={entry.id} /> )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-deck">
              <div className='card'>
                <div class="card-header">
                  Sleep
                </div>
                  {this.state.todaysSleepLog.map(entry => <SleepingLogEntryCardSimple {...entry} key={entry.id} /> )}
              </div>
            </div>
          </div>
        </div>        
      </div>
    );
  }
}

export default App;