
/**
 * Sleep Entry Id -- fldFe82K6RQMrA7Rp
 * Date -- fldokvZvdoj029eX5
 * Bedtime Start -- fldK8GbmGO9jZ2FpG
 * Bedtime End -- fldhyO5dpbz6twTew
 * Time in Bed -- fldykDt537ZlUMvRT
 * Total Sleep -- fldiciFRFMFNCjXhh
 */

const SleepingLogEntryCardSimple = ({fields}) => (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          🛌 {new Date(fields["Total Sleep"] * 1000).toISOString().slice(11, 19)} Total Sleep
        </h5>
        <p className="card-text"> {new Date(fields["Bedtime Start"]).toLocaleTimeString()} - {new Date(fields["Bedtime End"]).toLocaleTimeString()}</p>
        <p className="card-text">
          <small className="text-muted"> {new Date(fields["Time in Bed"] * 1000).toISOString().slice(11, 19)} Time in Bed</small>
        </p>
      </div>
    </div>
  );

  export default SleepingLogEntryCardSimple;