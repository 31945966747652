/**
 * Scheduled Workout Id -- fld6RVos8vGx0n4wq
 * Date -- fldtBLqsorZni9QSn
 * Discipline - Pretty -- fldxl5FkrPfQUHuUc
 * Distance -- fld8iEI5rQj0QwxSE
 * Duration -- fldnWfMtlqIHTAE7b
 */

const WorkoutCardSimple = ({fields}) => (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{fields["Discipline - Pretty"]}
        </h5>
        <p className="card-text"> {fields["Distance"]} </p>
        <p className="card-text"> {new Date(fields["Duration"] * 1000).toISOString().slice(11, 19)} </p>
        <p className="card-text">
          <small className="text-muted"> {} </small>
        </p>
      </div>
    </div>
  );

  export default WorkoutCardSimple;