
/**
 * Eating Entry Id -- fld48CZqCguBccVlX
 * Date -- fldVoP6fNBvjgyx11
 * Meal -- fldjU6nSEoVXHFFE5
 * Description -- fldeSslQZ6Wl0hgpx
 * Meal Prep -- fld5bif4j0ISRvGMe
 */

const EatingLogEntryCardSimple = ({fields}) => (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{fields["Meal"]} </h5>
        <p className="card-text"> {fields["Description"]} {fields["Metric"]} </p>
        <p className="card-text">
          <small className="text-muted"> {fields["Meal Prep"]} </small>
        </p>
      </div>
    </div>
  );

  export default EatingLogEntryCardSimple;