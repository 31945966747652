
/**
 * Scheduled Workout Id -- fldnDOWpU6rYQ8So7
 * Date -- fld5VrVQxRz0PAKM9
 * Discipline -- fldIbDOzIswux9GKr
 * Workout -- fldEfMHOumlDjRKx5
 * Metric -- fldX5ddBBdJKUEBe4
 */

const ScheduledWorkoutCardSimple = ({fields}) => (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{fields["Discipline"]} </h5>
        <p className="card-text"> {fields["Workout"]} {fields["Metric"]} </p>
        <p className="card-text">
          <small className="text-muted"> {fields["Status"]} </small>
        </p>
      </div>
    </div>
  );

  export default ScheduledWorkoutCardSimple;